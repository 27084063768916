<template>
<Base-Layout PageTitle="SUIVIE ATHLETE" page-default-back-Link="/accuiel">

  

<div v-if="dataSource==''" style="color:white">
   <center>
       <img src="assets/images/nodata2.png" style=" object-fit: cover;margin-top:150px;width:350px" />
   </center>


</div>


<div v-for="suivie in dataSource" :key="suivie.DATE_SUIVI_CLIENT" >
    {{suivie.DATE_SUIVI_CLIENT}}

    <ion-card class="ion-button2" style="height:60px;margin:5px">
        <ion-card-content>
            <center>
                <h2 style="color:#f1e501;"> <b>{{ suivie.DATE_SUIVI_CLIENT }}</b> </h2>
            </center>
        </ion-card-content>
    </ion-card>
    
    <ion-row>
        <ion-col size="4">
            <ion-card class="ion-button2" style=" width:100%;height:80px;">
                <ion-card-content style=" ">
                    <span class="first-text" style="font-size:10px;font-weight: 900;">TAILLE</span><br>
                    <h1 class="second-text" style="font-size:14px;margin-top:0px;font-weight: 900; ">{{ suivie.TAILLE_SUIVI_CLIENT }} cm</h1>

                </ion-card-content>
            </ion-card>
        </ion-col>

        <ion-col size="4">
            <ion-card class="ion-button2" style=" width:100%;height:80px;">
                <ion-card-content style=" ">
                    <span class="first-text" style="font-size:10px;font-weight: 900;">POIDS</span><br>
                    <h1 class="second-text" style="font-size:14px;margin-top:0px;font-weight: 900;">{{ suivie.POIDS_SUIVI_CLIENT }} Kg</h1>
                </ion-card-content>
            </ion-card>
        </ion-col>

        <ion-col size="4">
            <ion-card class="ion-button2" style=" width:100%;height:80px;">
                <ion-card-content style=" ">
                    <span class="first-text" style="font-size:10px;font-weight: 900;">AGE</span><br>
                    <h1 class="second-text" style="font-size:14px;margin-top:0px;font-weight: 900;">{{ suivie.AGE_CLIENT }} ans</h1>
                </ion-card-content>
            </ion-card>
        </ion-col>
    </ion-row>



    <ion-card style="--ion-background-color: #1d1d1f;float:left;margin:8px;width:99%">
        <ion-card-content style="">
            <center>
                <h2 style="color:#f1e501;"><b>Calcules</b> </h2>
            </center>
            <br>
            <span class="first-text" style="font-size:14px">Indice De Masse Corborelle (IMC%) </span><br>
            <span class="second-text">{{ suivie.IMC_SUIVI_CLIENT }}</span><br>
            <hr>
            <span class="first-text" style="font-size:14px">Indice De Masse GRAISSE (IMG%)</span><br>
            <span class="second-text">{{ suivie.IMG_SUIVI_CLIENT }}</span><br>
            <hr>
            <span class="first-text" style="font-size:14px">Indice De Masse Musculaire(IMM%)</span><br>
            <span class="second-text">{{ suivie.IMM_SUIVI_CLIENT }}</span><br>
            <hr>
            
            <span class="first-text" style="font-size:14px">Metabolisme de Base</span><br>
            <span class="second-text">{{ suivie.MB_SUIVI_CLIENT }}</span><br>

            <span class="first-text" style="font-size:14px">Besoin En Energie Journalier(DEJ-Kcal)</span><br>
            <span class="second-text">{{ suivie.BESOIN_ENERGIE_JOUR_CLIENT }}</span><br>
            <hr>
            <span class="first-text" style="font-size:14px">Objectif</span><br>
            <span class="second-text">{{ suivie.OBJECTIF_SUIVI_CLIENT }}</span><br>
            <hr>
            <span class="first-text" style="font-size:14px">Besoin En Energie Selon Objectif (Kcal)</span><br>
            <span class="second-text" style="font-size:18px; color:green;">{{ suivie.BESOIN_ENERGIE_SELON_OBJECTIF }}</span><br>
            <hr>
            <br>

            <div>
                <div style="width:42%;float:left;margin:10px">
                    <span class="first-text" style="font-size:14px;width:45%">Poitrine </span><br>
                    <span class="second-text" style="width:45%">{{ suivie.TOUR_POITRINE }}cm</span><br>
                    <hr>
                </div>
                <div style="width:42%;float:left;margin:10px">
                    <span class="first-text" style="font-size:14px;width:45%">Taille </span><br>
                    <span class="second-text" style="width:45%">{{ suivie.TOUR_TAILLE }}cm</span><br>
                    <hr>
                </div>
            </div>
            <div>
                <div style="width:42%;float:left;margin:10px">
                    <span class="first-text" style="font-size:14px;width:45%">Bras Gauche </span><br>
                    <span class="second-text" style="width:45%">{{ suivie.TOUR_BRAS_GAUCHE }}cm</span><br>
                    <hr>
                </div>
                <div style="width:42%;float:left;margin:10px">
                    <span class="first-text" style="font-size:14px;width:45%">Bras Droite </span><br>
                    <span class="second-text" style="width:45%">{{ suivie.TOUR_BRAS_DROIT }}cm</span><br>
                    <hr>
                </div>
            </div>
            <div>
                <div style="width:42%;float:left;margin:10px">
                    <span class="first-text" style="font-size:14px;width:45%">Cuisse Gauche </span><br>
                    <span class="second-text" style="width:45%">{{ suivie.TOUR_CUISSE_GAUCHE }}cm</span><br>
                    <hr>
                </div>
                <div style="width:42%;float:left;margin:10px">
                    <span class="first-text" style="font-size:14px;width:45%">Cuisse Droite </span><br>
                    <span class="second-text" style="width:45%">{{ suivie.TOUR_CUISSE_DROITE }}cm</span><br>
                    <hr>
                </div>
            </div>
            <div>
                <div style="width:42%;float:left;margin:10px">
                    <span class="first-text" style="font-size:14px;width:45%">Hanches </span><br>
                    <span class="second-text" style="width:45%">{{ suivie.TOUR_HANCHES }}cm</span><br>
                    <hr>
                </div>
                <div style="width:42%;float:left;margin:10px">
                    <span class="first-text" style="font-size:14px;width:45%">Abdomineaux </span><br>
                    <span class="second-text" style="width:45%">{{ suivie.TOUR_ABDOMEN }}cm</span><br>
                    <hr>
                </div>
            </div>
            <br>

            <div>
                <div style="float:left;margin:10px">
            <center>
                <h2 style="color:#f1e501;"><b>Besoins en MacroNutriments</b> </h2>
            </center>

            <div style="width:100%;float:left;margin:10px">
                    <span class="first-text" style="font-size:14px;width:100%">GLUCIDE </span><br>
                    <span class="second-text" style="width:100%">{{ suivie.BESOIN_GLUCIDE }}</span><br>
                    <hr>
                </div>
                <div style="width:100%;float:left;margin:10px">
                    <span class="first-text" style="font-size:14px;width:100%">PROTEINE </span><br>
                    <span class="second-text" style="width:100%">{{ suivie.BESOIN_PROTEINE }}</span><br>
                    <hr>
                </div>
                <div style="width:100%;float:left;margin:10px">
                    <span class="first-text" style="font-size:14px;width:100%">LIPIDE </span><br>
                    <span class="second-text" style="width:100%">{{ suivie.BESOIN_LIPIDE }}</span><br>
                    <hr>
                </div>

                </div>
            </div>



            
            
            <div>
                <div style="float:left;margin:10px">
                    <center>
                        <h2 style="color:#f1e501;"><b>Programme Allimentaire et Observations </b> </h2>
                    </center>
                    
                    <span class="second-text" style="">{{ suivie.OBSERVATION_SUIVI_CLIENT }}</span><br>

                </div>
            </div>
        </ion-card-content>
    </ion-card>

    
    <span></span>
<hr>


</div>


</Base-Layout>
</template>

<script>
import {toastController} from '@ionic/vue';
import {
    IonCard,
    IonRow,
    IonCol
} from '@ionic/vue';
  import {
    ipServerObjet as ipServerObjet
} from './Configserver.js';

export default {
    components: {
        IonCard,
        IonRow,
        IonCol
    },
    methods: { 
        async NoConnexion() {
            const toast = await toastController.create({
                message: 'Oups ! Il semble y avoir un problème avec votre connexion Internet',
                duration: 2000,
                position: 'bottom',
                color: 'danger',
                mode: 'ios'
            });
            await toast.present();
        }   
    },
    data() {
        return {
            dataSource: [],
             ipServer:'',
        };
    },
    async created() {
       for (const property in ipServerObjet) {
    this.ipServer = ipServerObjet[property].ip;
    }
        this.axios({
            //url: 'http://'+localStorage.getItem('ip')+':1500/api/GET_SUIVIE_CLIENT/0/' + localStorage.getItem('carte'),
            url: ''+this.ipServer+'/api/GET_SUIVIE_CLIENT/' + localStorage.getItem('carte'),
            method: 'get',
            timeout: 15000,
        }).then(response => {
            this.dataSource = response.data;
        }).catch(async () => {
                this.NoConnexion();
            });
			
    },
}
</script>

<style>
.first-text {
    font-weight: 600;
    font-size: 12px;
}

.second-text {
    color: white;
    font-weight: 600;
    font-size: 15px;
    white-space: pre-wrap;
}

hr {
    background: rgba(196, 196, 196, 0.418);
}
</style>
