<template>
<Base-Layout PageTitle="LOG IN" page-default-back-Link="/" style="--background-color:white">
    <admin-form @get-admin="getadmin"></admin-form>
</Base-Layout>
</template>

<script>
import {
    toastController
} from '@ionic/vue';
import AdminForm from '../components/admin.vue'
import {ipServerObjet as ipServerObjet} from './Configserver.js';
export default {
    data() {
        return {
            ipServer: '',
        }
    },
    async created() {
/*
        if (localStorage.getItem('carte') == null) {
            this.$router.push('/');
        } else {
            this.$router.push('/accuiel');
        }
*/
    },
    methods: {
        async NoConnexion() {
            const toast = await toastController.create({
                message: 'Oups ! Il semble y avoir un problème avec votre connexion Internet',
                duration: 2000,
                position: 'bottom',
                color: 'danger',
                mode: 'ios'
            });
            await toast.present();
        },

        async successful() {
            const toast = await toastController.create({
                message: 'login successful',
                duration: 1500,
                position: 'bottom',
                color: 'success',
                mode: 'ios'
            });
            await toast.present();
        },

        async incorrect() {
            const toast = await toastController.create({
                message: 'username or password incorrect',
                duration: 1500,
                position: 'bottom',
                color: 'danger',
                mode: 'ios'
            });
            await toast.present();
        },
        getadmin(AdminData) {

            for (const property in ipServerObjet) {
                this.ipServer = ipServerObjet[property].ip;
            }


 this.axios({
               url: ''+this.ipServer+'/api/CLIENT_PSW_EXIST/' + AdminData.user + '/' + AdminData.password,
                method: 'get',
                timeout: 15000,
            }).then(response => {

                if (response.data != '') {
                    this.successful();
                    
                    const carteData2 = {
                    carte: response.data[0].ID_RFID_CLIENT
                    };


                this.$store.dispatch('addcarte',carteData2);
                this.$router.push('/accuiel');

                }else{

//**CLIENT N'A PAS DE PSW VERIF S@IL EXISTE DN LES SALLES */          
 this.axios({
               url: ''+this.ipServer+'/api/CLIENT_EXIST/' + AdminData.user,
                method: 'get',
                timeout: 15000,
            }).then(response => {

                if (response.data != '') {

                    this.$router.push('/RegisterAdmin', 'forward', 'replace');

                }else{
                    this.incorrect();
                }

            }).catch(async () => {
                this.NoConnexion();
            });

                    
                }
            }).catch(async () => {
                this.NoConnexion();
            });
            


        }
    },

    components: {
        AdminForm
    }
}
</script>

<style>
ion-content {

    --ion-background-color: white;
}
</style>
