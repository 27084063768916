<template>
<Base-Layout PageTitle="PROFIL" page-default-back-Link="/accuiel">

<div>

    <div v-if="this.dataSource==null" style="color:white">
        <center>
            <img src="assets/images/nodata2.png" style=" object-fit: cover;margin-top:150px;width:350px" />
        </center>
        <ion-card router-link="/accuiel" style="--ion-background-color: #1d1d1f;  margin:10px">
            <ion-card-content style="padding:12px;padding-left:20px">
                <center>
                    Accueil
                </center>
            </ion-card-content>
        </ion-card>
    </div>



        <div v-for="Client in dataSource" :key="Client.ID_CLIENT">
            <center>

                <ion-thumbnail class="item-avatar" item-start style="width:130px;height:130px;margin-top:20px">
                    <img src="assets/images/noimage.jpg">
                </ion-thumbnail>
                
                <span class="first-text" style="font-size:25px;color:white">{{ Client.NOM_CLIENT }}</span><br>
                <span class="first-text" style="font-size:25px;color:white">{{ Client.PRENOM_CLIENT }}</span><br>
            </center>
            <ion-card style="--ion-background-color: #1d1d1f;margin:15px">
                <ion-card-content style="padding:12px;padding-left:20px">
                    <span class="first-text">CODE CLIENT</span> <br>
                    <span class="second-text">{{ Client.ID_CLIENT }} </span>
                </ion-card-content>
            </ion-card>

            <ion-card style="--ion-background-color: #1d1d1f;margin:15px">
                <ion-card-content style="padding:12px;padding-left:20px">
                    <span class="first-text">SEXE</span> <br>
                    <span class="second-text">{{ Client.SEXE }} </span>
                </ion-card-content>
            </ion-card>


            <ion-card style="--ion-background-color: #1d1d1f;margin:15px">
                <ion-card-content style="padding:12px;padding-left:20px">
                    <span class="first-text">NOM</span> <br>
                    <span class="second-text">{{ Client.NOM_CLIENT }}</span>
                </ion-card-content>
            </ion-card>
            <ion-card style="--ion-background-color: #1d1d1f;margin:15px">
                <ion-card-content style="padding:12px;padding-left:20px">
                    <span class="first-text">PRENOM</span> <br>
                    <span class="second-text">{{ Client.PRENOM_CLIENT }}</span>
                </ion-card-content>
            </ion-card>
            <ion-card style="--ion-background-color: #1d1d1f;margin:15px">
                <ion-card-content style="padding:12px;padding-left:20px">
                    <span class="first-text">CARTE CODE</span> <br>
                    <span class="second-text">{{ Client.ID_RFID_CLIENT }}</span>
                </ion-card-content>
            </ion-card>
            <ion-card style="--ion-background-color: #1d1d1f;margin:15px">
                <ion-card-content style="padding:12px;padding-left:20px">
                    <span class="first-text">DATE DE NAISSANCE</span> <br>
                    <span class="second-text">{{ Client.DATE_NAISSANCE_CLIENT }}</span>
                </ion-card-content>
            </ion-card>
            <ion-card style="--ion-background-color: #1d1d1f;margin:15px">
                <ion-card-content style="padding:12px;padding-left:20px">
                    <span class="first-text">TELEPHONE</span> <br>
                    <span class="second-text">{{ Client.TEL_CLIENT }}</span>
                </ion-card-content>
            </ion-card>
            <ion-card style="--ion-background-color: #1d1d1f;margin:15px">
                <ion-card-content style="padding:12px;padding-left:20px">
                    <span class="first-text">CREDIT TOTAL ABONNEMENTS</span> <br>
                    <span class="second-text" style="color:#fc3723">0{{ Client.CREDIT_ABONNEMENTS }} DA</span>
                </ion-card-content>
            </ion-card>
            <ion-card style="--ion-background-color: #1d1d1f;margin:15px">
                <ion-card-content style="padding:12px;padding-left:20px">
                    <span class="first-text">CREDIT TOTAL</span> <br>
                    <span class="second-text" style="color:#fc3723">0{{ Client.CREDIT_PRODUIT }} DA</span>
                </ion-card-content>
            </ion-card>

        </div>

    </div>

</Base-Layout>
</template>

<script>
import {
    IonCard,
    IonCardContent,
    IonThumbnail,
    toastController

} from '@ionic/vue';

import {
    ipServerObjet as ipServerObjet
} from './Configserver.js';

export default {

    methods: {
        async NoConnexion() {
            const toast = await toastController.create({
                message: 'Oups ! Il semble y avoir un problème avec votre connexion Internet',
                duration: 2000,
                position: 'bottom',
                color: 'danger',
                mode: 'ios'
            });
            await toast.present();
        },
        async successful() {
        const toast = await toastController.create({
          message: 'login successful',
          duration: 1500,
          position: 'bottom',
          color: 'success',
          mode:'ios'
        });
        await toast.present();
      },

      async incorrect() {
        const toast = await toastController.create({
          message: 'username or password incorrect',
          duration: 1500,
          position: 'bottom',
          color: 'danger',
          mode:'ios'
        });
        await toast.present();
      },

        GetProfile() {
            this.loaded = false;
            for (const property in ipServerObjet) {
                this.ipServer = ipServerObjet[property].ip;
            }
            this.axios({
                //url: 'http://'+localStorage.getItem('ip')+':1500/api/PROFIL/0/' + localStorage.getItem('carte'),
                url: ''+this.ipServer+'/api/PROFIL_CLIENT/' + localStorage.getItem('carte'),
                method: 'get',
                timeout: 15000,
            }).then(response => {
                this.dataSource = response.data;
                this.imgSrc = 'data:image/jpeg;base64,' + this.dataSource[0].IMAGE_CLIENT;
                this.loaded = true;
            }).catch(async () => {
                this.NoConnexion();
            });
			
        }
    },
    data() {
        return {
            dataSource: [],
            imgSrc: '',
            ipServer: '',
            loaded: false,
            spininloaded: false,
        };
    },
    async created() {
        if (localStorage.getItem('carte') == null) {
            this.$router.push('/');
        }
        this.GetProfile();

    },

    components: {
        IonCard,
        IonThumbnail,
        IonCardContent,
    },

}
</script>

<style>
.first-text {
    font-weight: 600;
    font-size: 12px;
}

.second-text {
    color: white;
    font-weight: 600;
    font-size: 15px;
}

ion-thumbnail {
    --size: 140px;
    --border-radius: 13%;
}

ion-skeleton-text {
    --border-radius: 12px;
    --background: rgba(255, 238, 0, 0.644);
    --background-rgb: 255, 254, 255;
}

ion-modal {

    --border-radius: 15px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}
</style>
