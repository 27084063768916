<template>
<Base-Layout PageTitle="CHAIER D'ENTRAINEMENT" page-default-back-Link="/accuiel">
    


    <ion-loading v-if="ProgressBar"  trigger="open-loading" mode="ios"></ion-loading>


        

     <div v-if="datainfo==''" style="color:white">
        <center>
            <img src="assets/images/nodata2.png" style=" object-fit: cover;margin-top:150px;width:350px" />
        </center>


    </div>



    <ion-page ref="page">

        <div v-if="datainfo!=''">
            <div class="ion-padding">
                <ion-card v-for="infoprogramme in datainfo" :key="infoprogramme.DESIGNATION_PROGRAMME" style=" background: url(assets/images/entrainement.png);
                        background-repeat: no-repeat; background-size: cover;margin:5px;height:220px;">
                    <div style="margin-left:60px;">
                        <h1 style="color:#ececec;font-size:20px;height:150px"> <b> {{infoprogramme.DESIGNATION_PROGRAMME}}</b> </h1>
                        <ion-row style="height:115px;">

                            <ion-col style="margin-left:0px">
                                <img src="assets/images/icons/wheight.png" style="object-fit: cover;width:30px" />
                                <span class="second-text" style="font-size:20px;color:#ececec;">{{infoprogramme.POID_CLIENT}}</span>
                                <span style="color:#effe4c;font-size:14px;margin-left:10px">
                                    <b> {{infoprogramme.DATE_DEBUT_PROGRAMME_CLIENT}}-{{infoprogramme.DATE_FIN_PROGRAMME_CLIENT}} </b> </span>
                            </ion-col>
                        </ion-row>
                    </div>
                </ion-card>
            </div>
            <div class="container ion-padding">
                <div>
                    <ion-card style=" background: url(assets/images/samedi.png);
                        background-repeat: no-repeat; background-size: cover;margin:5px;width:150px;height:180px;">
                        <ion-row style="height:115px">
                            <ion-col>
                                <div style="margin-top:50px">
                                    <div v-for="MuscleJoure in datajour" :key="MuscleJoure.ID_JOUR">
                                        <div v-if="MuscleJoure.ID_JOUR =='1'">
                                            <span style="color:#ececec;font-size:12px"> <b>{{ MuscleJoure.MUSCLE }}</b> </span>
                                            <br>
                                        </div>
                                    </div>
                                </div>
                            </ion-col>
                        </ion-row>
                        <div style="margin:10px;">
                            <ion-button id="Samedi" expand="block" @click="GetDataSamedi()" size="small" class="ion-button1">Afficher</ion-button>
                        </div>
                    </ion-card>
                </div>

                <div>
                    <ion-card style=" background: url(assets/images/dimanche.png);
                        background-repeat: no-repeat; background-size: cover;margin:5px;width:150px;height:180px;">
                        <ion-row style="height:115px">
                            <ion-col>
                                <div style="margin-top:50px">
                                    <div v-for="MuscleJoure in datajour" :key="MuscleJoure.ID_JOUR">
                                        <div v-if="MuscleJoure.ID_JOUR =='2'">
                                            <span style="color:#ececec;font-size:12px"> <b>{{ MuscleJoure.MUSCLE }}</b> </span>
                                            <br>
                                        </div>
                                    </div>
                                </div>
                            </ion-col>
                        </ion-row>
                        <div style="margin:10px;">
                            <ion-button id="Dimanche" expand="block" @click="GetDataDimanche()" size="small" class="ion-button1">Afficher</ion-button>
                        </div>
                    </ion-card>
                </div>

                <div>
                    <ion-card style=" background: url(assets/images/lundi.png);
                        background-repeat: no-repeat; background-size: cover;margin:5px;width:150px;height:180px;">
                        <ion-row style="height:115px">
                            <ion-col>
                                <div style="margin-top:50px">
                                    <div v-for="MuscleJoure in datajour" :key="MuscleJoure.ID_JOUR">
                                        <div v-if="MuscleJoure.ID_JOUR =='3'">
                                            <span style="color:#ececec;font-size:12px"> <b>{{ MuscleJoure.MUSCLE }}</b> </span>
                                            <br>
                                        </div>
                                    </div>
                                </div>
                            </ion-col>
                        </ion-row>
                        <div style="margin:10px;">
                            <ion-button id="Lundi" expand="block" @click="GetDataLundi()" size="small" class="ion-button1">Afficher</ion-button>
                        </div>
                    </ion-card>
                </div>

                <div>
                    <ion-card style=" background: url(assets/images/mardi.png);
                        background-repeat: no-repeat; background-size: cover;margin:5px;width:150px;height:180px;">
                        <ion-row style="height:115px">
                            <ion-col>
                                <div style="margin-top:50px">
                                    <div v-for="MuscleJoure in datajour" :key="MuscleJoure.ID_JOUR">
                                        <div v-if="MuscleJoure.ID_JOUR =='4'">
                                            <span style="color:#ececec;font-size:12px"> <b>{{ MuscleJoure.MUSCLE }}</b> </span>
                                            <br>
                                        </div>
                                    </div>
                                </div>
                            </ion-col>
                        </ion-row>
                        <div style="margin:10px;">
                            <ion-button id="Mardi" expand="block" @click="GetDataMardi()" size="small" class="ion-button1">Afficher</ion-button>
                        </div>
                    </ion-card>
                </div>

                <div>
                    <ion-card style=" background: url(assets/images/mercredi.png);
                        background-repeat: no-repeat; background-size: cover;margin:5px;width:150px;height:180px;">
                        <ion-row style="height:115px">
                            <ion-col>
                                <div style="margin-top:50px">
                                    <div v-for="MuscleJoure in datajour" :key="MuscleJoure.ID_JOUR">
                                        <div v-if="MuscleJoure.ID_JOUR =='5'">
                                            <span style="color:#ececec;font-size:12px"> <b>{{ MuscleJoure.MUSCLE }}</b> </span>
                                            <br>
                                        </div>
                                    </div>
                                </div>
                            </ion-col>
                        </ion-row>
                        <div style="margin:10px;">
                            <ion-button id="Mercredi" expand="block" @click="GetDataMercredi()" size="small" class="ion-button1">Afficher</ion-button>
                        </div>
                    </ion-card>
                </div>
                <div>
                    <ion-card style=" background: url(assets/images/jeudi.png);
                        background-repeat: no-repeat; background-size: cover;margin:5px;width:150px;height:180px;">
                        <ion-row style="height:115px">
                            <ion-col>
                                <div style="margin-top:50px">
                                    <div v-for="MuscleJoure in datajour" :key="MuscleJoure.ID_JOUR">
                                        <div v-if="MuscleJoure.ID_JOUR =='6'">
                                            <span style="color:#ececec;font-size:12px"> <b>{{ MuscleJoure.MUSCLE }}</b> </span>
                                            <br>
                                        </div>
                                    </div>
                                </div>
                            </ion-col>
                        </ion-row>
                        <div style="margin:10px;">
                            <ion-button id="Jeudi" expand="block" @click="GetDataJeudi()" size="small" class="ion-button1">Afficher</ion-button>
                        </div>
                    </ion-card>
                </div>
                <div>
                    <ion-card style=" background: url(assets/images/vendredi.png);
                        background-repeat: no-repeat; background-size: cover;margin:5px;width:150px;height:180px;">
                        <ion-row style="height:115px">
                            <ion-col>
                                <div style="margin-top:50px">
                                    <div v-for="MuscleJoure in datajour" :key="MuscleJoure.ID_JOUR">
                                        <div v-if="MuscleJoure.ID_JOUR =='7'">
                                            <span style="color:#ececec;font-size:12px"> <b>{{ MuscleJoure.MUSCLE }}</b> </span>
                                            <br>
                                        </div>
                                    </div>
                                </div>
                            </ion-col>
                        </ion-row>
                        <div style="margin:10px;">
                            <ion-button id="Vendredi" expand="block" @click="GetDataVendredi()" size="small" class="ion-button1">Afficher</ion-button>
                        </div>
                    </ion-card>
                </div>
            </div>

            <ion-content class="ion-padding">

                <!--Samedi-->
                <ion-modal ref="Samedi" trigger="Samedi" :presenting-element="presentingElement">
                    <ion-header>
                        <ion-toolbar>
                            <ion-title>Samedi</ion-title>
                            <ion-buttons slot="end">
                                <ion-button @click="Samedi()">Close</ion-button>
                            </ion-buttons>
                        </ion-toolbar>
                    </ion-header>
                    <ion-content>

                        <!--Item EXERCICE-->

                        
                        <ion-loading v-if="ProgressBar"  trigger="open-loading" mode="ios"></ion-loading>

                        <ion-card v-for="DetailProgramme in dataSource" :key="DetailProgramme.ID_JOUR" style="--ion-background-color: #1d1d1f;width:45%;float:left;margin:8px;height:280px">

                            <ion-row>
                                <ion-col>
                                   
                                    <img :src="this.ipServer+'/'+DetailProgramme.IMAGE1_EXERCICE" style="border-radius:15px;  object-fit: cover;margin:0px;width:100%" />
                                </ion-col>
                            </ion-row>
                            <div style="padding:10px">
                                <span class="second-text" style="font-size:12px">
                                    {{DetailProgramme.ID_EXERCICE}} | {{DetailProgramme.DESIGNATION_EXERCICE}}</span><br>
                                <span class="first-text" style="font-size:10px">{{DetailProgramme.MUSCLE}}</span><br>
                                <ion-row style="margin:-5px">
                                    <ion-col size="8">
                                        <span class="second-text" style="font-size:12px;color:#fff600">
                                            <b>{{DetailProgramme.NBR_SERIES}}x{{DetailProgramme.NBR_REPETITIONS}}</b></span>
                                    </ion-col>
                                    <ion-col size="4">
                                        <img src="assets/images/icons/clock.png" style="border-radius:15px;  object-fit: cover;margin:-2px;width:15px" />
                                        <span class="second-text" style="font-size:12px;color:#fff600;margin-left:5px"> {{DetailProgramme.REPOS_DUREE}}</span>
                                    </ion-col>
                                </ion-row>
                            </div>
                        </ion-card>
                        <!--Item EXERCICE-->
                    </ion-content>
                </ion-modal>


                <!--Dimanche-->
                <ion-modal ref="Dimanche" trigger="Dimanche" :presenting-element="presentingElement2">
                    <ion-header>
                        <ion-toolbar>
                            <ion-title>Dimanche</ion-title>
                            <ion-buttons slot="end">
                                <ion-button @click="Dimanche()">Close</ion-button>
                            </ion-buttons>
                        </ion-toolbar>
                    </ion-header>
                    <ion-content>
                        <!--Item EXERCICE-->
                        <ion-loading v-if="ProgressBar"  trigger="open-loading" mode="ios"></ion-loading>
                        <ion-card v-for="DetailProgramme in dataSource" :key="DetailProgramme.ID_JOUR" style="--ion-background-color: #1d1d1f;width:45%;float:left;margin:8px;height:280px">

                            <ion-row>
                                <ion-col>
                                    <img :src="this.ipServer+'/'+DetailProgramme.IMAGE1_EXERCICE" style="border-radius:15px;  object-fit: cover;margin:0px;width:100%" />
                                </ion-col>
                            </ion-row>
                            <div style="padding:10px">
                                <span class="second-text" style="font-size:12px">
                                    {{DetailProgramme.ID_EXERCICE}} | {{DetailProgramme.DESIGNATION_EXERCICE}}</span><br>
                                <span class="first-text" style="font-size:10px">{{DetailProgramme.MUSCLE}}</span><br>
                                <ion-row style="margin:-5px">
                                    <ion-col size="8">
                                        <span class="second-text" style="font-size:12px;color:#e7e13c">
                                            <b>{{DetailProgramme.NBR_SERIES}}x{{DetailProgramme.NBR_REPETITIONS}}</b></span>
                                    </ion-col>
                                    <ion-col size="4">
                                        <img src="assets/images/icons/clock.png" style="border-radius:15px;  object-fit: cover;margin:-2px;width:15px" />
                                        <span class="second-text" style="font-size:12px;color:#e7e13c;margin-left:5px"> {{DetailProgramme.REPOS_DUREE}}</span>
                                    </ion-col>
                                </ion-row>
                            </div>
                        </ion-card>
                        <!--Item EXERCICE-->
                    </ion-content>
                </ion-modal>
                <!--Lundi-->
                <ion-modal ref="Lundi" trigger="Lundi" :presenting-element="presentingElement3">
                    <ion-header>
                        <ion-toolbar>
                            <ion-title>Lundi</ion-title>
                            <ion-buttons slot="end">
                                <ion-button @click="Lundi()">Close</ion-button>
                            </ion-buttons>
                        </ion-toolbar>
                    </ion-header>
                    <ion-content>
                        <!--Item EXERCICE-->
                        <ion-loading v-if="ProgressBar"  trigger="open-loading" mode="ios"></ion-loading>
                        <ion-card v-for="DetailProgramme in dataSource" :key="DetailProgramme.ID_JOUR" style="--ion-background-color: #1d1d1f;width:45%;float:left;margin:8px;height:280px">

                            <ion-row>
                                <ion-col>
                                    <img :src="this.ipServer+'/'+DetailProgramme.IMAGE1_EXERCICE" style="border-radius:15px;  object-fit: cover;margin:0px;width:100%" />
                                </ion-col>
                            </ion-row>
                            <div style="padding:10px">
                                <span class="second-text" style="font-size:12px">
                                    {{DetailProgramme.ID_EXERCICE}} | {{DetailProgramme.DESIGNATION_EXERCICE}}</span><br>
                                <span class="first-text" style="font-size:10px">{{DetailProgramme.MUSCLE}}</span><br>
                                <ion-row style="margin:-5px">
                                    <ion-col size="8">
                                        <span class="second-text" style="font-size:12px;color:#e7e13c">
                                            <b>{{DetailProgramme.NBR_SERIES}}x{{DetailProgramme.NBR_REPETITIONS}}</b></span>
                                    </ion-col>
                                    <ion-col size="4">
                                        <img src="assets/images/icons/clock.png" style="border-radius:15px;  object-fit: cover;margin:-2px;width:15px" />
                                        <span class="second-text" style="font-size:12px;color:#e7e13c;margin-left:5px"> {{DetailProgramme.REPOS_DUREE}}</span>
                                    </ion-col>
                                </ion-row>
                            </div>
                        </ion-card>
                        <!--Item EXERCICE-->
                    </ion-content>
                </ion-modal>
                <!--Mardi-->
                <ion-modal ref="Mardi" trigger="Mardi" :presenting-element="presentingElement4">
                    <ion-header>
                        <ion-toolbar>
                            <ion-title>Mardi</ion-title>
                            <ion-buttons slot="end">
                                <ion-button @click="Mardi()">Close</ion-button>
                            </ion-buttons>
                        </ion-toolbar>
                    </ion-header>
                    <ion-content>
                        <!--Item EXERCICE-->
                        <ion-loading v-if="ProgressBar"  trigger="open-loading" mode="ios"></ion-loading>
                        <ion-card v-for="DetailProgramme in dataSource" :key="DetailProgramme.ID_JOUR" style="--ion-background-color: #1d1d1f;width:45%;float:left;margin:8px;height:280px">

                            <ion-row>
                                <ion-col>
                                    <img :src="this.ipServer+'/'+DetailProgramme.IMAGE1_EXERCICE" style="border-radius:15px;  object-fit: cover;margin:0px;width:100%" />
                                </ion-col>
                            </ion-row>
                            <div style="padding:10px">
                                <span class="second-text" style="font-size:12px">
                                    {{DetailProgramme.ID_EXERCICE}} | {{DetailProgramme.DESIGNATION_EXERCICE}}</span><br>
                                <span class="first-text" style="font-size:10px">{{DetailProgramme.MUSCLE}}</span><br>
                                <ion-row style="margin:-5px">
                                    <ion-col size="8">
                                        <span class="second-text" style="font-size:12px;color:#e7e13c">
                                            <b>{{DetailProgramme.NBR_SERIES}}x{{DetailProgramme.NBR_REPETITIONS}}</b></span>
                                    </ion-col>
                                    <ion-col size="4">
                                        <img src="assets/images/icons/clock.png" style="border-radius:15px;  object-fit: cover;margin:-2px;width:15px" />
                                        <span class="second-text" style="font-size:12px;color:#e7e13c;margin-left:5px"> {{DetailProgramme.REPOS_DUREE}}</span>
                                    </ion-col>
                                </ion-row>
                            </div>
                        </ion-card>
                        <!--Item EXERCICE-->
                    </ion-content>
                </ion-modal>
                <!--Mercredi-->
                <ion-modal ref="Mercredi" trigger="Mercredi" :presenting-element="presentingElement5">
                    <ion-header>
                        <ion-toolbar>
                            <ion-title>Mercredi</ion-title>
                            <ion-buttons slot="end">
                                <ion-button @click="Mercredi()">Close</ion-button>
                            </ion-buttons>
                        </ion-toolbar>
                    </ion-header>
                    <ion-content>
                        <!--Item EXERCICE-->
                        <ion-loading v-if="ProgressBar"  trigger="open-loading" mode="ios"></ion-loading>
                        <ion-card v-for="DetailProgramme in dataSource" :key="DetailProgramme.ID_JOUR" style="--ion-background-color: #1d1d1f;width:45%;float:left;margin:8px;height:280px">

                            <ion-row>
                                <ion-col>
                                    <img :src="this.ipServer+'/'+DetailProgramme.IMAGE1_EXERCICE" style="border-radius:15px;  object-fit: cover;margin:0px;width:100%" />
                                </ion-col>
                            </ion-row>
                            <div style="padding:10px">
                                <span class="second-text" style="font-size:12px">
                                    {{DetailProgramme.ID_EXERCICE}} | {{DetailProgramme.DESIGNATION_EXERCICE}}</span><br>
                                <span class="first-text" style="font-size:10px">{{DetailProgramme.MUSCLE}}</span><br>
                                <ion-row style="margin:-5px">
                                    <ion-col size="8">
                                        <span class="second-text" style="font-size:12px;color:#e7e13c">
                                            <b>{{DetailProgramme.NBR_SERIES}}x{{DetailProgramme.NBR_REPETITIONS}}</b></span>
                                    </ion-col>
                                    <ion-col size="4">
                                        <img src="assets/images/icons/clock.png" style="border-radius:15px;  object-fit: cover;margin:-2px;width:15px" />
                                        <span class="second-text" style="font-size:12px;color:#e7e13c;margin-left:5px"> {{DetailProgramme.REPOS_DUREE}}</span>
                                    </ion-col>
                                </ion-row>
                            </div>
                        </ion-card>
                        <!--Item EXERCICE-->
                    </ion-content>
                </ion-modal>
                <!--Jeudi-->
                <ion-modal ref="Jeudi" trigger="Jeudi" :presenting-element="presentingElement6">
                    <ion-header>
                        <ion-toolbar>
                            <ion-title>Jeudi</ion-title>
                            <ion-buttons slot="end">
                                <ion-button @click="Jeudi()">Close</ion-button>
                            </ion-buttons>
                        </ion-toolbar>
                    </ion-header>
                    <ion-content>
                        <!--Item EXERCICE-->
                        <ion-loading v-if="ProgressBar"  trigger="open-loading" mode="ios"></ion-loading>
                        <ion-card v-for="DetailProgramme in dataSource" :key="DetailProgramme.ID_JOUR" style="--ion-background-color: #1d1d1f;width:45%;float:left;margin:8px;height:280px">

                            <ion-row>
                                <ion-col>
                                    <img :src="this.ipServer+'/'+DetailProgramme.IMAGE1_EXERCICE" style="border-radius:15px;  object-fit: cover;margin:0px;width:100%" />
                                </ion-col>
                            </ion-row>
                            <div style="padding:10px">
                                <span class="second-text" style="font-size:12px">
                                    {{DetailProgramme.ID_EXERCICE}} | {{DetailProgramme.DESIGNATION_EXERCICE}}</span><br>
                                <span class="first-text" style="font-size:10px">{{DetailProgramme.MUSCLE}}</span><br>
                                <ion-row style="margin:-5px">
                                    <ion-col size="8">
                                        <span class="second-text" style="font-size:12px;color:#e7e13c">
                                            <b>{{DetailProgramme.NBR_SERIES}}x{{DetailProgramme.NBR_REPETITIONS}}</b></span>
                                    </ion-col>
                                    <ion-col size="4">
                                        <img src="assets/images/icons/clock.png" style="border-radius:15px;  object-fit: cover;margin:-2px;width:15px" />
                                        <span class="second-text" style="font-size:12px;color:#e7e13c;margin-left:5px"> {{DetailProgramme.REPOS_DUREE}}</span>
                                    </ion-col>
                                </ion-row>
                            </div>
                        </ion-card>
                        <!--Item EXERCICE-->
                    </ion-content>
                </ion-modal>
                <!--Vendredi-->
                <ion-modal ref="Vendredi" trigger="Vendredi" :presenting-element="presentingElement7">
                    <ion-header>
                        <ion-toolbar>
                            <ion-title>Vendredi</ion-title>
                            <ion-buttons slot="end">
                                <ion-button @click="Vendredi()">Close</ion-button>
                            </ion-buttons>
                        </ion-toolbar>
                    </ion-header>
                    <ion-content>
                        <!--Item EXERCICE-->
                        <ion-loading v-if="ProgressBar"  trigger="open-loading" mode="ios"></ion-loading>
                        <ion-card v-for="DetailProgramme in dataSource" :key="DetailProgramme.ID_JOUR" style="--ion-background-color: #1d1d1f;width:45%;float:left;margin:8px;height:280px">
                            <ion-row>
                                <ion-col>
                                    <img :src="this.ipServer+'/'+DetailProgramme.IMAGE1_EXERCICE" style="border-radius:15px;  object-fit: cover;margin:0px;width:100%" />
                                </ion-col>
                            </ion-row>
                            <div style="padding:10px">
                                <span class="second-text" style="font-size:12px">
                                    {{DetailProgramme.ID_EXERCICE}} | {{DetailProgramme.DESIGNATION_EXERCICE}}</span><br>
                                <span class="first-text" style="font-size:10px">{{DetailProgramme.MUSCLE}}</span><br>
                                <ion-row style="margin:-5px">
                                    <ion-col size="8">
                                        <span class="second-text" style="font-size:12px;color:#e7e13c">
                                            <b>{{DetailProgramme.NBR_SERIES}}x{{DetailProgramme.NBR_REPETITIONS}}</b></span>
                                    </ion-col>
                                    <ion-col size="4">
                                        <img src="assets/images/icons/clock.png" style="border-radius:15px;  object-fit: cover;margin:-2px;width:15px" />
                                        <span class="second-text" style="font-size:12px;color:#e7e13c;margin-left:5px"> {{DetailProgramme.REPOS_DUREE}}</span>
                                    </ion-col>
                                </ion-row>
                            </div>
                        </ion-card>
                        <!--Item EXERCICE-->
                    </ion-content>
                </ion-modal>
            </ion-content>
        </div>

    </ion-page>

</Base-Layout>
</template>

<script>
import {toastController} from '@ionic/vue';
import {
    IonCard,
    IonButtons,
    IonButton,
    IonModal,
    IonContent,
    IonToolbar,
    IonTitle,
    IonPage,
    IonRow,
    IonCol,
    IonLoading 
} from '@ionic/vue';
 import {
    ipServerObjet as ipServerObjet
} from './Configserver.js';

export default {
    data() {
        return {
            presentingElement: null,
            presentingElement2: null,
            presentingElement3: null,
            presentingElement4: null,
            presentingElement5: null,
            presentingElement6: null,
            presentingElement7: null,
            dataSource: [],
            datajour: [],
            datainfo: [],
            ProgressBar: false,
            ID_PROGRAMME:'',
        };
    },
    

    async created() {
    if(localStorage.getItem('carte')==null){
    this.$router.push('/');
  }
             for (const property in ipServerObjet) {
    this.ipServer = ipServerObjet[property].ip;
    }

        this.axios({
            //url: 'http://'+localStorage.getItem('ip')+':1500/api/MUSCLES_PROGRAMME_CLIENT/0/' + localStorage.getItem('carte'),
            url: ''+this.ipServer+'/api/MUSCLES_PROGRAMME_CLIENT/' + localStorage.getItem('carte'),
            method: 'get',
            timeout: 15000,
        }).then(response => {
            this.datajour = response.data;
        }).catch(async () => {
                this.NoConnexion();
            });

        this.axios({
           // url: 'http://'+localStorage.getItem('ip')+':1500/api/INFO_PROGRAMME_CLIENT/0/' + localStorage.getItem('carte'),
           url: ''+this.ipServer+'/api/INFO_PROGRAMME_CLIENT/' + localStorage.getItem('carte'),
            method: 'get',
            timeout: 15000,
        }).then(response => {
            this.ProgressBar = true;
            try{
            this.datainfo = response.data;
            this.ID_PROGRAMME=this.datainfo[0].ID_PROGRAMME;
            }
            catch
            {
                this.ProgressBar = false;
            }
            this.ProgressBar = false;
        }).catch(async () => {
                this.NoConnexion();
            });

    },




    
    methods: {
        async NoConnexion() {
            const toast = await toastController.create({
                message: 'Oups ! Il semble y avoir un problème avec votre connexion Internet',
                duration: 2000,
                position: 'bottom',
                color: 'danger',
                mode: 'ios'
            });
            await toast.present();
        },
        GetDataSamedi() {
                 for (const property in ipServerObjet) {
    this.ipServer = ipServerObjet[property].ip;
    }

    this.ProgressBar = true;

            this.dataSource = "";
            this.axios({
                 //url: 'http://'+localStorage.getItem('ip')+':1500/api/PROGRAMME_CLIENT/1/' + localStorage.getItem('carte')+'/'+this.ID_PROGRAMME,
                 url: ''+this.ipServer+'/api/PROGRAMME_CLIENT/' + localStorage.getItem('carte')+"/1",
                method: 'get',
                timeout: 15000,
            }).then(response => {
                this.dataSource = response.data;

                
                this.ProgressBar = false;
            

            }).catch(async () => {
                this.NoConnexion();
            });
        },
        GetDataDimanche() {
                 for (const property in ipServerObjet) {
    this.ipServer = ipServerObjet[property].ip;
    }
    this.ProgressBar = true;
            this.dataSource = "";
            this.axios({
                 //url: 'http://'+localStorage.getItem('ip')+':1500/api/PROGRAMME_CLIENT/2/' + localStorage.getItem('carte')+'/'+this.ID_PROGRAMME,
                 url: ''+this.ipServer+'/api/PROGRAMME_CLIENT/' + localStorage.getItem('carte')+"/2",
                method: 'get',
                timeout: 15000,
            }).then(response => {
                this.dataSource = response.data;
                
                this.ProgressBar = false;
            
            }).catch(async () => {
                this.NoConnexion();
            });
        },
        GetDataLundi() {
                 for (const property in ipServerObjet) {
    this.ipServer = ipServerObjet[property].ip;
    }
    this.ProgressBar = true;
            this.dataSource = "";
            this.axios({
                //url: 'http://'+localStorage.getItem('ip')+':1500/api/PROGRAMME_CLIENT/3/' + localStorage.getItem('carte')+'/'+this.ID_PROGRAMME,
                url: ''+this.ipServer+'/api/PROGRAMME_CLIENT/' + localStorage.getItem('carte')+"/3",
                method: 'get',
                timeout: 15000,
            }).then(response => {
                this.dataSource = response.data;
                
                this.ProgressBar = false;
            
            }).catch(async () => {
                this.NoConnexion();
            });
        },

        GetDataMardi() {
                 for (const property in ipServerObjet) {
    this.ipServer = ipServerObjet[property].ip;
    }
    this.ProgressBar = true;
            this.dataSource = "";
            this.axios({
                //url: 'http://'+localStorage.getItem('ip')+':1500/api/PROGRAMME_CLIENT/4/' + localStorage.getItem('carte')+'/'+this.ID_PROGRAMME,
                url: ''+this.ipServer+'/api/PROGRAMME_CLIENT/' + localStorage.getItem('carte')+"/4",
                method: 'get',
                timeout: 15000,
            }).then(response => {
                this.dataSource = response.data;
               
                this.ProgressBar = false;
            
            }).catch(async () => {
                this.NoConnexion();
            });
        },
        GetDataMercredi() {
                 for (const property in ipServerObjet) {
    this.ipServer = ipServerObjet[property].ip;
    }
    this.ProgressBar = true;
            this.dataSource = "";
            this.axios({
                 //url: 'http://'+localStorage.getItem('ip')+':1500/api/PROGRAMME_CLIENT/5/' + localStorage.getItem('carte')+'/'+this.ID_PROGRAMME,
                 url: ''+this.ipServer+'/api/PROGRAMME_CLIENT/' + localStorage.getItem('carte')+"/5",
                method: 'get',
                timeout: 15000,
            }).then(response => {
                this.dataSource = response.data;
             
                this.ProgressBar = false;
            
            }).catch(async () => {
                this.NoConnexion();
            });
        },
        GetDataJeudi() {
                 for (const property in ipServerObjet) {
    this.ipServer = ipServerObjet[property].ip;
    }
    this.ProgressBar = true;
            this.dataSource = "";
            this.axios({
                 //url: 'http://'+localStorage.getItem('ip')+':1500/api/PROGRAMME_CLIENT/6/' + localStorage.getItem('carte')+'/'+this.ID_PROGRAMME,
                 url: ''+this.ipServer+'/api/PROGRAMME_CLIENT/' + localStorage.getItem('carte')+"/6",
                method: 'get',
                timeout: 15000,
            }).then(response => {
                this.dataSource = response.data;
                this.ProgressBar = false;
            
            }).catch(async () => {
                this.NoConnexion();
            });
        },
        GetDataVendredi() {
                 for (const property in ipServerObjet) {
    this.ipServer = ipServerObjet[property].ip;
    }
    this.ProgressBar = true;
            this.dataSource = "";
            this.axios({
                 //url: 'http://'+localStorage.getItem('ip')+':1500/api/PROGRAMME_CLIENT/7/' + localStorage.getItem('carte')+'/'+this.ID_PROGRAMME,
                 url: ''+this.ipServer+'/api/PROGRAMME_CLIENT/' + localStorage.getItem('carte')+"/7",
                method: 'get',
                timeout: 15000,
            }).then(response => {
                this.dataSource = response.data;
               
                this.ProgressBar = false;
            
            }).catch(async () => {
                this.NoConnexion();
            });
        },
        Samedi() {
            this.$refs.Samedi.$el.dismiss();
        },
        Dimanche() {
            this.$refs.Dimanche.$el.dismiss();
        },
        Lundi() {
            this.$refs.Lundi.$el.dismiss();
        },
        Mardi() {
            this.$refs.Mardi.$el.dismiss();
        },
        Mercredi() {
            this.$refs.Mercredi.$el.dismiss();
        },
        Jeudi() {
            this.$refs.Jeudi.$el.dismiss();
        },
        Vendredi() {
            this.$refs.Vendredi.$el.dismiss();
        },
    },
    mounted() {
        this.presentingElement = this.$refs.page.$el;
        this.presentingElement2 = this.$refs.page.$el;
        this.presentingElement3 = this.$refs.page.$el;
        this.presentingElement4 = this.$refs.page.$el;
        this.presentingElement5 = this.$refs.page.$el;
        this.presentingElement6 = this.$refs.page.$el;
        this.presentingElement7 = this.$refs.page.$el;
    },
    components: {
        IonCard,
        IonButtons,
        IonButton,
        IonModal,
        IonContent,
        IonToolbar,
        IonTitle,
        IonPage,
        IonRow,
        IonCol,
        IonLoading 
    }
}
</script>

<style>
.first-text {
    font-weight: 600;
    font-size: 12px;
}

.second-text {
    color: white;
    font-weight: 600;
    font-size: 15px;
}

ion-button {
    --background: #1C1C1C;
    --opacity: 0.8;
    padding: 0px;
    --border-radius: 10px !important;
    width: 100px;
    height: 100px;
    float: left;
    margin: 4px;
}

.ion-button1 {
    --background: #ffffffd3;
    --opacity: 0.8;
    padding: 0px;
    --border-radius: 20px !important;
    width: 80;
    height: 40px;
    float: left;
    color: black;
}
</style>
<style>
  ion-loading.custom-loading {
    --background: red;
    --spinner-color: #1c6dff;

    color: #1c6dff;
  }
</style>