<template>
<Base-Layout PageTitle="Bgr InBody" page-default-back-Link="/accuiel">
    <div v-if="this.json==''" style="color:white">
        <center>
            <img src="assets/images/nodata2.png" style=" object-fit: cover;margin-top:150px;width:350px" />
        </center>
        <ion-card router-link="/accuiel" style="--ion-background-color: #1d1d1f;  margin:10px">
            <ion-card-content style="padding:12px;padding-left:20px">
                <center>
                    Accueil
                </center>
            </ion-card-content>
        </ion-card>
    </div>

    <div v-if="this.json != ''">
        <br>
        <span class="first-text " style="font-size:16px;color:#d2d2d2">Résumé du test InBody</span> <br>
        <br>

        <ion-row v-for="LastData in json" :key="LastData.INFOS_CLIENT_LAST_DATA">

            <ion-col size="4" v-for="Data in LastData.INFOS_CLIENT_LAST_DATA" :key="Data.WT">
                <ion-card class="ion-button2" :style="`width:100%;height:105%;border: 2px solid ${this.color1}`" @click="ChartLignData('Poids (kg)')">
                    <center style="margin-top:10px">
                        <div style="margin-top:15px"><span class="first-text" style="font-size:12px">Poids (kg)</span></div>
                        <div style="margin-top:5px">
                            <span class="first-text" style="font-size:30px;color:white"> {{ Data.WT }}</span>
                            <ion-badge v-if="Data.WT<Data.WT_MIN" slot="start" style="--background: purple;width:80px;margin-top:5px">inférieur</ion-badge>
                            <ion-badge v-if="Data.WT>Data.WT_MAX" slot="start" style="--background: #ff0042;width:80px;margin-top:3px">Supérieur</ion-badge>
                        </div>
                    </center>
                </ion-card>
            </ion-col>
            <ion-col size="4" v-for="Data in LastData.INFOS_CLIENT_LAST_DATA" :key="Data.WT">
                <ion-card class="ion-button2" :style="`width:100%;height:105%;border: 2px solid ${this.color2}`" @click="ChartLignData('Muscles (kg)')">
                    <center style="margin-top:10px">
                        <div style="margin-top:15px"><span class="first-text" style="font-size:12px">Muscles (kg)</span></div>
                        <div style="margin-top:5px">
                            <span class="first-text" style="font-size:30px;color:white"> {{ Data.SMM }}</span>

                            <ion-badge v-if="Data.SMM<Data.SMM_MIN" slot="start" style="--background: purple;width:80px;margin-top:5px">inférieur</ion-badge>
                            <ion-badge v-if="Data.SMM>Data.SMM_MIN" slot="start" style="--background: #ff0042;width:80px;margin-top:3px">Supérieur</ion-badge>

                        </div>
                    </center>
                </ion-card>
            </ion-col>
            <ion-col size="4" v-for="Data in LastData.INFOS_CLIENT_LAST_DATA" :key="Data.WT">
                <ion-card class="ion-button2" :style="`width:100%;height:105%;border: 2px solid ${this.color3}`" @click="ChartLignData('Taux de graisse corporelle (%)')">
                    <center style="margin-top:5px;">

                        <div style=" "><span class="first-text" style="font-size:12px">Taux de graisse corporelle (%)</span></div>
                        <div style=" ">
                            <span class="first-text" style="font-size:30px;color:white">{{ Data.PBF }}</span>
                            <ion-badge v-if="Data.PBF<Data.PBF_MIN" slot="start" style="--background: purple;width:80px;margin-top:5px">inférieur</ion-badge>
                            <ion-badge v-if="Data.PBF>Data.PBF_MIN" slot="start" style="--background: #ff0042;width:80px;margin-top:3px">Supérieur</ion-badge>
                        </div>
                    </center>
                </ion-card>
            </ion-col>
        </ion-row>

        <br>
        <ion-card v-for="LastData in json" :key="LastData.AVANCEMENT" class="ion-button2" style=" width:100%;height:250px;padding:10px">

            <center>
                <chart-ligne :type="typeChart" :data="LastData.AVANCEMENT" :key="keyChartLign"></chart-ligne>
            </center>

        </ion-card>

        <br>
        <span class="first-text" style="font-size:16px;color:#d2d2d2">Apport quotidien selon objectif</span> <br>
        <br>

        <ion-card v-for="LastData in json" :key="LastData.INFOS_CLIENT_LAST_DATA" style="--ion-background-color: #1d1d1f;  margin:10px">
            <ion-card-content v-for="Data in LastData.INFOS_CLIENT_LAST_DATA" :key="Data.WT" style="padding:12px;padding-left:20px">
                <ion-badge slot="start" style="--background: #fff600;color:#1c1c1c; margin-top:3px">
                </ion-badge>

                <ion-row>
                    <ion-col size="1" style="padding:0px">
                        <img src="/assets/images/objectifs.png" style="object-fit: cover;width:110%;">
                    </ion-col>
                    <ion-col size="6">
                        <span class="first-text" style="font-size:14px;color:white">Objectif</span>
                    </ion-col>
                    <ion-col size="5">
                        <center>
                            <span class="first-text" style="font-size:13px;color:white">{{ Data.OBJECTIF }}
                            </span>
                        </center>
                    </ion-col>
                </ion-row>

                <ion-row style="margin-top:20px">
                    <ion-col size="1" style="padding:0px">
                        <img src="/assets/images/glucides.png" style="object-fit: cover;width:110%;">
                    </ion-col>
                    <ion-col size="9">
                        <span class="first-text" style="font-size:14px;color:white">Glucides </span>
                    </ion-col>
                    <ion-col size="2">
                        <center>
                            <span class="first-text" style="font-size:14px;color:white">{{ Data.GLUCIDE }}
                                <span style="color:#929292">
                                    g
                                </span> </span>
                        </center>
                    </ion-col>
                </ion-row>

                <ion-row style="margin-top:20px">
                    <ion-col size="1" style="padding:0px">
                        <img src="/assets/images/proteine.png" style="object-fit: cover;width:110%;">
                    </ion-col>
                    <ion-col size="9">
                        <span class="first-text" style="font-size:14px;color:white">Protéines </span>
                    </ion-col>
                    <ion-col size="2">
                        <center>
                            <span class="first-text" style="font-size:14px;color:white">{{ Data.PROTEINE }}
                                <span style="color:#929292">
                                    g
                                </span> </span>
                        </center>
                    </ion-col>
                </ion-row>

                <ion-row style="margin-top:20px">
                    <ion-col size="1" style="padding:0px">
                        <img src="/assets/images/graisse.png" style="object-fit: cover;width:110%;">
                    </ion-col>
                    <ion-col size="9">
                        <span class="first-text" style="font-size:14px;color:white">Lipide </span>
                    </ion-col>
                    <ion-col size="2">
                        <center>
                            <span class="first-text" style="font-size:14px;color:white">{{ Data.PROTEINE }}
                                <span style="color:#929292"> g</span> </span>
                        </center>
                    </ion-col>
                </ion-row>

                <ion-row style="margin-top:20px">
                    <ion-col size="1" style="padding:0px">
                        <img src="/assets/images/calories.png" style="object-fit: cover;width:110%;">
                    </ion-col>
                    <ion-col size="8">
                        <span class="first-text" style="font-size:14px;color:white">Calories </span>
                    </ion-col>
                    <ion-col size="3">
                        <center>
                            <span class="first-text" style="font-size:14px;color:white">{{ Data.ENERGIE_SELON_OBJECTIF }}
                                <span style="color:#929292">
                                    kcal
                                </span>
                            </span>
                        </center>
                    </ion-col>
                </ion-row>



            </ion-card-content>
        </ion-card>

        <br>
        <span class="first-text" style="font-size:14px;color:#d2d2d2">RECOMANDATIONS</span><br>
        <br>

        <ion-card v-for="LastData in json" :key="LastData.INFOS_CLIENT_LAST_DATA" style="--ion-background-color: #1d1d1f;  margin:10px">
            <ion-card-content v-for="Data in LastData.INFOS_CLIENT_LAST_DATA" :key="Data.WT" style="padding:12px;padding-left:20px">
                <ion-badge slot="start" style="--background: #fff600;color:#1c1c1c; margin-top:3px">
                </ion-badge>


        <ion-row style="margin-top:20px">
                    <ion-col size="9">
                        <span class="first-text" style="font-size:14px;color:white">Le poids ideal  </span>
                    </ion-col>
                    <ion-col size="3">
                        <center>
                            <span class="first-text" style="font-size:14px;color:white">{{ Data.TW }}
                                <span style="color:#929292">
                                    kcal
                                </span>
                            </span>
                        </center>
                    </ion-col>
        </ion-row>

        <ion-row style="margin-top:20px">
                    <ion-col size="9">
                        <span class="first-text" style="font-size:14px;color:white">Le poids Cible (ideal)  </span>
                    </ion-col>
                    <ion-col size="3">
                        <center>
                            <span class="first-text" style="font-size:14px;color:white">{{ Data.TW }}
                                <span style="color:#929292">
                                    kcal
                                </span>
                            </span>
                        </center>
                    </ion-col>
        </ion-row>

        <ion-row style="margin-top:20px">
                    <ion-col size="9">
                        <span class="first-text" style="font-size:14px;color:white">Recommandation Poids</span>
                    </ion-col>
                    <ion-col size="3">
                        <center>
                            <span class="first-text" style="font-size:14px;color:white">{{ Data.WC }}
                                <span style="color:#929292">
                                    kcal
                                </span>
                            </span>
                        </center>
                    </ion-col>
        </ion-row>

        <ion-row style="margin-top:20px">
                    <ion-col size="9">
                        <span class="first-text" style="font-size:14px;color:white">Recommandation Masse Grasse</span>
                    </ion-col>
                    <ion-col size="3">
                        <center>
                            <span class="first-text" style="font-size:14px;color:white">{{ Data.FC }}
                                <span style="color:#929292">
                                    kcal
                                </span>
                            </span>
                        </center>
                    </ion-col>
        </ion-row>

        <ion-row style="margin-top:20px">
                    <ion-col size="9">
                        <span class="first-text" style="font-size:14px;color:white">Recommandation Masse Musculaire</span>
                    </ion-col>
                    <ion-col size="3">
                        <center>
                            <span class="first-text" style="font-size:14px;color:white">{{ Data.MC }}
                                <span style="color:#929292">
                                    kcal
                                </span>
                            </span>
                        </center>
                    </ion-col>
        </ion-row>

            </ion-card-content>
        </ion-card>


        <br>
        <span class="first-text" style="font-size:14px;color:#d2d2d2">HISTORIQUE</span><br>
        <br>

        <div v-for="LastData in json" :key="LastData.INFOS_CLIENT_LAST_DATA">

            <ion-card v-for="InbodyDataHystorique in LastData.HISTORIQUE_CLIENT_INBODY_DATES" :key="InbodyDataHystorique.ID_SUIVI_CLIENT_AUTO" :router-link="`/InBodyDetaille/${InbodyDataHystorique.ID_SUIVI_CLIENT_AUTO}`" style="--ion-background-color: #1d1d1f;  margin:10px">
                <ion-card-content style="padding:12px;padding-left:20px">
                    <ion-badge slot="start" style="--background: #fff600;color:#1c1c1c; margin-top:3px">
                        {{InbodyDataHystorique.DATE_SUIVI_CLIENT}}</ion-badge>
                    <ion-row>
                        <ion-col size="1">
                        </ion-col>
                        <ion-col size="2">
                            <center>
                                <span class="first-text" style="font-size:12px;color:white">Poids (kg)</span><br>
                                <span class="first-text" style="font-size:14px">{{ InbodyDataHystorique.WT }}</span><br>
                            </center>
                        </ion-col>
                        <ion-col size="3">
                            <center>
                                <span class="first-text" style="font-size:12px;color:white">Muscles (kg) </span><br>
                                <span class="first-text" style="font-size:14px">{{ InbodyDataHystorique.SMM }}</span><br>
                            </center>
                        </ion-col>
                        <ion-col size="5">
                            <center>
                                <span class="first-text" style="font-size:12px;color:white">Taux de graisse corporelle (%)</span><br>
                                <span class="first-text" style="font-size:14px">{{ InbodyDataHystorique.PBF }}</span><br>
                            </center>
                        </ion-col>
                    </ion-row>

                </ion-card-content>
            </ion-card>

        </div>
    </div>
</Base-Layout>
</template>

<script>
import {toastController} from '@ionic/vue';
import {
    IonCard,
    IonCol,
    IonRow,
    IonCardContent

} from '@ionic/vue';

import ChartLigne from '../components/chartLign.vue'

import {
    ipServerObjet as ipServerObjet
} from './Configserver.js';

export default {
    name: 'LineChart',
    data() {
        return {

            InbodyData: [],
            InbodyLastData: [],
            ipServer: '',
            keyChartLign: 0,
            typeChart: 'Poids (kg)',
            color1: '#ffe400',
            color2: '#1C1C1C',
            color3: '#1C1C1C',
            json: [],
            drapo: 0,
            checkstorege: 'NON',

        };

    },
    methods: {
        async NoConnexion() {
            const toast = await toastController.create({
                message: 'Oups ! Il semble y avoir un problème avec votre connexion Internet',
                duration: 2000,
                position: 'bottom',
                color: 'danger',
                mode: 'ios'
            });
            await toast.present();
        },

        ChartLignData(TypeChart) {

            if (TypeChart == "Poids (kg)") {
                this.color1 = "#ffe400";
                this.color2 = "#1C1C1C";
                this.color3 = "#1C1C1C";
            }
            if (TypeChart == "Muscles (kg)") {
                this.color1 = "#1C1C1C";
                this.color2 = "#ffe400";
                this.color3 = "#1C1C1C";
            }
            if (TypeChart == "Taux de graisse corporelle (%)") {
                this.color1 = "#1C1C1C";
                this.color2 = "#1C1C1C";
                this.color3 = "#ffe400";
            }

            this.typeChart = TypeChart;
            this.keyChartLign += 1;
        },

    },

    async created() {
        for (const property in ipServerObjet) {
            this.ipServer = ipServerObjet[property].ip;
        }
        // test if  data
        this.axios({
            url: localStorage.getItem('ip') + '/api/GET_HISTORIQUE_INBODY/' + localStorage.getItem('carte'),
            method: 'get',
            timeout: 15000,
        }).then(response => {
            // this.drapo=1;
          //  this.$store.dispatch('addstorage1', JSON.stringify(response.data));
            this.json = response.data;
 
        }).catch(async () => {
                this.NoConnexion();
            });

        if (localStorage.getItem('nonExistent') !== null) {
            this.checkstorege = "OUI";
        }
        //this.json= JSON.parse(localStorage.getItem('storage1'));

    },
    components: {
        IonCard,
        IonCol,
        IonRow,
        IonCardContent,
        ChartLigne
    }
}
</script>

<style>
.first-text {
    font-weight: 600;
    font-size: 12px;
}

.second-text {
    color: white;
    font-weight: 600;
    font-size: 15px;
}
</style>
