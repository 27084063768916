<template>
<Base-Layout page-default-back-Link="/accuiel" PageTitle="BGR GROUP CYCLE">
    <div style="--ion-background-color: #1d1d1f;" id="BIKE" expand="block">
        <ion-row>
            <ion-col @click="selectbike(bike.BIKE, bike.NOM, bike.RFID)" size="3" v-for="bike in dataSource" :key="bike.BIKE" style="margin-top:10px">
                <ion-card class="ion-button2" :style="`width:100%;height:108%;background-color:${ bike.COLOR }; `">
                    <div>
                        <div style="padding:2px " @click="presentAlert">
                            <center>
                                <ion-img src="assets/images/bike.png" style="object-fit: cover;width:100%;background-color:#232323;border-radius:15px "> </ion-img>
                            </center>
                        </div>
                        <div style="margin-top:5px">
                            <center>
                                <span v-if="bike.NOM!=''" class="first-text" style="font-size:12px;color:#0e0e0e">{{ bike.NOM }} <br>{{ bike.BIKE }}</span>
                                <span v-if="bike.NOM==''" class="first-text" style="font-size:12px;color:white">{{ bike.BIKE }}</span>
                            </center>
                        </div>
                    </div>
                </ion-card>
            </ion-col>
        </ion-row>
        <br><br>
        <center>
            <h2 style="color:red" v-if="reservedbikeresultat=='reserved'">vous êtes déjà réservé</h2>
        </center>
    </div>




    <ion-modal ref="BIKE" trigger="BIKE" :presenting-element="presentingElement">
        <ion-header>
            <ion-toolbar>
                <ion-title>SELECT BIKE</ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="bike()">Close</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content>

            <!--Item HISTORIQUE LOCATION ARMOIRE-->
            <ion-card style="--ion-background-color: #1d1d1f;">
                <ion-card-content>

                    <center>
                        <h1>
                            <span v-if="this.reservedbike!=''" class="first-text" style="font-size:22px;color:white">
                                BIKE RESERVED BY <br>
                                <span style="color:#fff200"> {{ reservedbike }}</span> 

                                <div v-if="this.ID_RFID_OREG==this.selectedridbike">
                                    <br><br><br>
                                    <ion-card @click="Delete_reserverbike()"   class="ion-button2" style="width:100%;height:100px ;background-color:#fff200">
                                        <center><br><br>
                                            <span class="first-text" style="font-size:22px;color:#1a1a1a">Annuler Reservation</span><br>
                                        </center>
                                    </ion-card>
                                </div>
                            </span>


                            <span v-if="this.reservedbike==''" class="first-text" style="font-size:22px;color:white">{{ idbike }}
                                <br><br>
                                <ion-card @click="reserverbike()"   class="ion-button2" style="width:100%;height:100px ;background-color:#fff200">
                                    <center><br><br>
                                        <span class="first-text" style="font-size:22px;color:#1a1a1a">Reserver</span><br>
                                    </center>
                                </ion-card>
                            </span>


                        </h1>
                    </center>
                </ion-card-content>
            </ion-card>
            <!--Item HISTORIQUE LOCATION ARMOIRE-->
        </ion-content>
    </ion-modal>
    <br>

</Base-Layout>
</template>

<script>
import {toastController} from '@ionic/vue';
import {
    IonCard,
    IonCol,
    IonRow,
    IonImg,
    IonModal,
    IonContent,
} from '@ionic/vue';


export default {
     data() {
        return {
            dataSource: [],
            idbike: '',
            reservedbike: '',
            salle:'1',
            reservedbikeresultat:'',
            selectedridbike:'',
            ID_RFID_OREG:''
        }
    },
    methods: {
        async NoConnexion() {
            const toast = await toastController.create({
                message: 'Oups ! Il semble y avoir un problème avec votre connexion Internet',
                duration: 2000,
                position: 'bottom',
                color: 'danger',
                mode: 'ios'
            });
            await toast.present();
        },
        getbikes(){
            this.axios({
             url: "http://"+localStorage.getItem('ip')+":1500/api/GET_BIKES/"+this.salle+"/0",
            method: 'get',
            timeout: 15000,
        }).then(response => {
            this.dataSource = response.data;
        }).catch(async () => {
                this.NoConnexion();
            });



        },
        reserverbike(){
             this.axios({
             url: "http://"+localStorage.getItem('ip')+":1500/api/UPDATE_BIKE_STAT/"+this.salle+"/"+this.idbike+"-"+localStorage.getItem('carte'),
            method: 'get',
            timeout: 15000,
        }).then(response => {
            this.reservedbikeresultat = response.data[0].BIKE;
        }).catch(async () => {
                this.NoConnexion();
            });

            
        this.$refs.BIKE.$el.dismiss();
         this.getbikes();
         this.getbikes();
        },
        Delete_reserverbike(){

             this.axios({
             url: "http://"+localStorage.getItem('ip')+":1500/api/UPDATE_BIKE_STAT_DELETE/"+this.salle+"/"+this.idbike+"-"+localStorage.getItem('carte'),
            method: 'get',
            timeout: 15000,
        });
        this.$refs.BIKE.$el.dismiss();
         this.getbikes();
         this.getbikes();
        },
        bike() {
            this.$refs.BIKE.$el.dismiss();
        },
        selectbike(id, reservedbike, rfid_cli) {
            this.idbike = id;
            this.reservedbike = reservedbike;
            this.selectedridbike = rfid_cli;

        }
    },

    async created() {
if(localStorage.getItem('carte')==null){
this.$router.push('/');
  }
  this.ID_RFID_OREG = localStorage.getItem('carte');
this.getbikes();

    },
    components: {
        IonCard,
        IonCol,
        IonRow,
        IonImg,
        IonModal,
        IonContent,
    }
}
</script>

<style>
.first-text {
    font-weight: 600;
    font-size: 12px;
}

.second-text {
    color: white;
    font-weight: 600;
    font-size: 15px;
}
</style>
